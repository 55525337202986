<template>
  <module-container prominent>
    <h3>{{ $t("welcome.message") }}</h3>
  </module-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class Home extends Vue {}
</script>
